/**  */

// Pollyfill for intersection observer API
import 'intersection-observer';

// eslint-disable-next-line
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import { isIOS } from 'react-device-detect';

import { themeVars } from '../../config/styles';
import { bp } from '../../config/breakpoints';

import bgPattern from '../../assets/home-mask.svg';

// Components
import Nav from '../Header/Nav';
import HomeHeroContent from './HomeHeroContent';

const HomeHeroHeader = ({ backgroundImage, heroContent, heroTitle, handleMenuOpen }) => {
  const [hasAnimated, setHasAnimated] = useState(false);

  function handleScroll(percentage) {
    if (percentage >= 0.1) {
      setHasAnimated(true);
    }
  }

  const cssWrapper = css`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 1;
    background-color: ${themeVars.root.primary};
    ${backgroundImage && `background-image: url(${backgroundImage})`};
    background-attachment: ${isIOS ? 'scroll' : 'fixed'};

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      background-size: cover;

      top: 0;
      left: 0;

    }
  `;

  return (
    <header css={cssWrapper}>
      <Nav handleMenuOpen={handleMenuOpen} />
      <HomeHeroContent content={heroContent} title={heroTitle} />
    </header>
  );
};

HomeHeroHeader.defaultProps = {
  heroTitle: '',
};

HomeHeroHeader.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
  heroContent: PropTypes.string.isRequired,
  heroTitle: PropTypes.string,
};

export default HomeHeroHeader;
