import React from 'react';
import shortid from 'shortid';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

// Components
import ContentModules from '../components/Content/ContentModules';
import LayoutHome from '../components/Layouts/LayoutHome';

const Homepage = ({ pageContext }) => {
  const { restData } = pageContext;

  return (
    !isEmpty(restData) && (
      <LayoutHome
        backgroundImage={restData.acf.home_hero_bg.sizes.hero_bg}
        heroContent={restData.acf.home_hero_content}
        heroTitle={restData.acf.home_hero_title}
        title={restData.title.rendered}
        yoastMeta={restData.yoast_meta}
      >
        <LazyLoad>
          <main>
            <div className="content-modules">
              {restData.acf.content_modules.map((module) => {
                return <ContentModules key={shortid.generate()} module={module} />;
              })}
            </div>
          </main>
        </LazyLoad>
      </LayoutHome>
    )
  );
};

export default Homepage;

Homepage.defaultProps = {
  acf: PropTypes.shape({
    page_image_slider: null,
    page_image_slider_images: null,
    content_modules: null,
  }),
};

Homepage.propTypes = {
  pageContext: PropTypes.shape({
    restData: PropTypes.shape({
      acf: PropTypes.shape({
        content_modules: PropTypes.arrayOf(PropTypes.shape({})),
        page_hero_bg: PropTypes.shape({}).isRequired,
        page_image_slider: PropTypes.shape({}),
        page_image_slider_images: PropTypes.arrayOf(PropTypes.shape({})),
      }).isRequired,
      title: PropTypes.shape({
        rendered: PropTypes.string.isRequired,
      }),
      yoast_meta: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};
