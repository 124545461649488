/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import { Link } from 'gatsby';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// Config
import { bp } from '../../config/breakpoints';

// Components
import MUIButton from '../Common/Button/MUIButton';

const HomeHeroContent = ({ content, title }) => {
  const cssWrapper = css`
    height: 100%;
    width: 100%;
  `;
  const cssTitle = css`
    color: #14255E;
    font-size: 10rem;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 30px;
    margin-top: 10%;

    font-family: aviano-sans, sans-serif;
    font-weight: 900;
    font-style: normal;

    @media (max-width: ${bp.mx_md}) {
      font-size: 4rem;
    }
  `;

  const cssInner = css`
    @media (min-width: ${bp.mn_lg}) {
      max-width: 50%;
    }
  `;

  const cssContent = css`
    color: #000000;
    font-weight: 400;
    font-size: 1.7rem;
    height: 62px;
    @media (min-width: ${bp.mn_lg}) {
      width: 490px;
    }
    @media (max-width: ${bp.mn_md}){
      width: 75%;
      margin-bottom: 20%;
    }
    color: #000000;
    font-family: ""Work Sans", Helvetica, Arial sans-serif;";
    font-size: 28px;
    letter-spacing: 0;
    line-height: 31px;
  `;

  const cssIcon = css`
    margin-left: 15px;
  `;

  return (
    <div css={cssWrapper}>
      <Container>
        <Row>
          <Col xs={12}>
            <div css={cssInner}>
              <h1 css={cssTitle}>{title}</h1>
              <p css={cssContent}>{content}</p>

              <Link to="/contact/">
                <MUIButton xlarge>
                  Get in touch
                </MUIButton>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

HomeHeroContent.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default HomeHeroContent;
