import React, { useState } from 'react';

import PropTypes from 'prop-types';

// Components
import Footer from '../Footer/Footer';
import HomeHeroHeader from '../Hero/HomeHeroHeader';
import Menu from '../Header/Menu/Menu';
import SEO from '../Common/SEO';

const LayoutHome = ({ children, backgroundImage, heroContent, heroTitle, yoastMeta, title }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  return (
    <>
      <SEO
        title={yoastMeta.yoast_wpseo_title || title}
        description={yoastMeta.yoast_wpseo_metadesc}
        ogDesc={yoastMeta.yoast_wpseo_facebook_description}
        ogImg={yoastMeta.yoast_wpseo_facebook_image}
        ogTitle={yoastMeta.yoast_wpseo_facebook_title}
        twitterDesc={yoastMeta.yoast_wpseo_twitter_description}
        twitterImg={yoastMeta.yoast_wpseo_twitter_image}
        twitterTitle={yoastMeta.yoast_wpseo_twitter_title}
      />

      <Menu handleMenuClose={handleMenuClose} isMenuOpen={isMenuOpen} />

      <HomeHeroHeader
        backgroundImage={backgroundImage}
        handleMenuOpen={handleMenuOpen}
        heroContent={heroContent}
        heroTitle={heroTitle}
      />

      <div>{children}</div>

      <Footer />
    </>
  );
};

LayoutHome.defaultProps = {
  heroTitle: '',
  title: null,
};

LayoutHome.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  heroContent: PropTypes.string.isRequired,
  heroTitle: PropTypes.string,
  title: PropTypes.string,
  yoastMeta: PropTypes.shape({
    yoast_wpseo_twitter_title: PropTypes.string,
    yoast_wpseo_twitter_image: PropTypes.string,
    yoast_wpseo_twitter_description: PropTypes.string,
    yoast_wpseo_facebook_title: PropTypes.string,
    yoast_wpseo_facebook_image: PropTypes.string,
    yoast_wpseo_facebook_description: PropTypes.string,
    yoast_wpseo_metadesc: PropTypes.string,
    yoast_wpseo_title: PropTypes.string,
  }).isRequired,
};

export default LayoutHome;
